import React, { useEffect, useRef } from 'react';
import { Plot } from '@int/geotoolkit/plot/Plot';
import { TrackType } from '@int/geotoolkit/welllog/TrackType';
import { LogCurve } from '@int/geotoolkit/welllog/LogCurve';
import { LogData } from '@int/geotoolkit/welllog/data/LogData';
import { createWellLogWidget } from './helpers/common.js';
import './helpers/style.css';
import { useSimpleDataSource } from './helpers/SimpleDataSource.js';

const defaults = {
  zoomInScale: 5 / 4,
  zoomOutScale: 4 / 5,
};

const BigData = () => {
  const plotRef = useRef(null);
  const dataSource = useSimpleDataSource(); // Using the custom hook to get the data source
  const widgetRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    if (!dataSource) {
      console.log('Data source is not loaded yet.');
      return;
    }

    console.log('DataSource loaded:', dataSource);

    // Initialize the widget with depth index type and unit
    const widget = createWellLogWidget({
      indextype: 'number',
      indexunit: 'ft',
    });

    // Add the depth (index) track to display depth values on the left side
    widget.addTrack(TrackType.IndexTrack);

    // Add a linear track for log curves
    const track = widget.addTrack(TrackType.LinearTrack);

    // Dynamically create and add curves based on available curve names in the data source
    dataSource.curveNames.forEach((curveName, index) => {
      const color = index % 2 === 0 ? 'green' : 'red'; // Alternate colors for curves
      const curve = new LogCurve(new LogData(curveName)).setLineStyle(color);
      curve.setName(curveName);
      track.addChild(curve);
    });

    // Hide the track header if needed
    const header = track.getHeader();
    header.setVisible(false);

    // Connect data source
    dataSource.connect(widget);

    // Set an appropriate depth scale for full height
    widget.setDepthScale(100);

    const plot = new Plot({
      canvaselement: canvasRef.current,
      root: widget,
    });

    plotRef.current = plot;
    widgetRef.current = widget;

    console.log('Widget and Plot initialized');

    return () => {
      plot.dispose();
      dataSource.dispose();
    };
  }, [dataSource]);

  const zoomIn = () => {
    if (widgetRef.current) {
      widgetRef.current.scale(defaults.zoomInScale);
    }
  };

  const zoomOut = () => {
    if (widgetRef.current) {
      widgetRef.current.scale(defaults.zoomOutScale);
    }
  };

  const fitToHeight = () => {
    if (widgetRef.current) {
      widgetRef.current.fitToHeight();
    }
  };

  return (
    <div style={{ backgroundColor: 'white' }}>
      <canvas ref={canvasRef} width="1600" height="800" />
      <div>
        <button onClick={zoomIn}>Zoom In</button>
        <button onClick={zoomOut}>Zoom Out</button>
        <button onClick={fitToHeight}>Fit to Height</button>
      </div>
    </div>
  );
};

export default BigData;
