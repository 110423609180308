import { compose } from 'redux';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import withStyles from '@material-ui/styles/withStyles';

import { BasePage } from 'altus-ui-components';
import { getSummarizedDataStateFromState } from 'app/app.selectors';
import {
  getIntegrityManagementIntegritiesFromState,
  getIntegrityManagementIntegritiesFromStateByRiskPrinciple,
} from 'features/settings/integrityManagement/helpers/integrityManagement.selectors';
import {
  INTEGRITY_MANAGEMENT_ACTIONS,
  RISK_PRINCIPLE_TYPES,
} from 'features/settings/integrityManagement/helpers/integrityManagement.constants';
import { loadIntegrityManagementIntegritiesForWell } from 'features/settings/integrityManagement/helpers/integrityManagement.actions';

import useWellbore from 'features/wells/hooks/useWellbore';
import WellIntegrityTable from 'features/wells/integrity/WellIntegrityTable';
import { useState } from 'react';
import IntegrityRiskMatrix from 'features/wells/integrity/IntegrityRiskMatrix';

// TODO make a single component for well consequence and likelihood
const WellLikelihoodOfFailureContainer = ({
  classes,
  fieldId,
  dataState,
  wellboreId,
  filteredIntegrities,
  integrities,
  dispatchLoadIntegrityManagementForWell,
}) => {
  const { wellbore } = useWellbore(wellboreId);
  const [externalWellboreId, setExternalWellboreId] = useState(null);
  const [finalScore, setFinalScore] = useState(0);

  useEffect(() => {
    if (wellbore.get('externalWellboreExternalId')) {
      dispatchLoadIntegrityManagementForWell(
        wellbore.get('externalWellboreExternalId'),
      );
      setExternalWellboreId(wellbore.get('externalWellboreExternalId'));
    }
  }, [dispatchLoadIntegrityManagementForWell, wellbore]);

  useEffect(() => {
    let score = 1;
    integrities &&
      integrities.forEach((integrity) => {
        score *= integrity.riskPrincipleScore.score;
      });
    setFinalScore(score.toFixed(3));
  }, [integrities]);

  return (
    <BasePage dataState={dataState}>
      <Grid item container spacing={2} className={classes.tableContainer}>
        {externalWellboreId && (
          <Grid item spacing={2} xs={12} className={classes.container}>
            <Grid item xs={7}>
              <WellIntegrityTable
                integrities={filteredIntegrities}
                fieldId={fieldId}
                wellboreId={wellboreId}
                externalWellboreId={externalWellboreId}
                title={RISK_PRINCIPLE_TYPES.LIKELIHOOD_OF_FAILURE}
              />
            </Grid>
            <Grid item xs={5} className={classes.matrix}>
              <IntegrityRiskMatrix
                finalScore={finalScore}
                filteredIntegrities={filteredIntegrities}
                title={RISK_PRINCIPLE_TYPES.LIKELIHOOD_OF_FAILURE}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </BasePage>
  );
};

const styles = (theme) => ({
  tableContainer: {
    padding: theme.spacing(2),
  },
  matrix: {
    background: theme.palette.tabs.background.light,
    padding: theme.spacing(4),
    marginLeft: theme.spacing(2),
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
});

export default compose(
  connect(
    (state) => ({
      filteredIntegrities:
        getIntegrityManagementIntegritiesFromStateByRiskPrinciple(
          state,
          RISK_PRINCIPLE_TYPES.LIKELIHOOD_OF_FAILURE,
        ),
      integrities: getIntegrityManagementIntegritiesFromState(state),
      dataState: getSummarizedDataStateFromState(
        state,
        INTEGRITY_MANAGEMENT_ACTIONS.GET_ALL_INTEGRITY_MANAGEMENT_INTEGRITIES_FOR_WELL,
      ),
    }),
    {
      dispatchLoadIntegrityManagementForWell:
        loadIntegrityManagementIntegritiesForWell,
    },
  ),
  withStyles(styles),
)(WellLikelihoodOfFailureContainer);
