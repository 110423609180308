import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Box,
  Tab,
  Tabs,
  Grid,
  List,
  Button,
  Divider,
  ListItem,
  Checkbox,
  Collapse,
  Typography,
  IconButton,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import CommentIcon from '@material-ui/icons/Comment';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import withStyles from '@material-ui/styles/withStyles';
import { EMPTY_LIST, MAX_UPLOAD_FILE_SIZE } from 'app/app.constants';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {
  ATTACHMENTS_DATE_FORMAT,
  INTEGRITY_ATTACHMENT_FILES_TABS,
} from 'features/settings/integrityManagement/helpers/integrityManagement.constants';

const UploadIntegrityAttachmentDrawerContent = ({
  classes,
  toggleDrawer,
  projectFiles,
  wellboreFiles,
  handleUpdateAttachments,
  isSubmitting,
  setIsSubmitting,
}) => {
  const [activeTab, setActiveTab] = useState(
    INTEGRITY_ATTACHMENT_FILES_TABS.WELL,
  );

  const [newWellboreFiles, setNewWellboreFiles] = useState(EMPTY_LIST);
  const [newProjectFiles, setNewProjectFiles] = useState(EMPTY_LIST);

  const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    setNewWellboreFiles(wellboreFiles);
  }, [wellboreFiles]);

  useEffect(() => {
    setNewProjectFiles(projectFiles);
  }, [projectFiles]);

  const handleWellboreFilesCheckboxToggle = (externalId) => {
    setNewWellboreFiles((prev) =>
      prev.map((file) =>
        file.externalId === externalId
          ? { ...file, isChecked: !file.isChecked }
          : file,
      ),
    );
  };

  const handleProjectFilesCheckboxToggle = (projectId, externalId) => {
    setNewProjectFiles((prev) =>
      prev.updateIn([projectId], (files) =>
        files.map((file) =>
          file.externalId === externalId
            ? { ...file, isChecked: !file.isChecked }
            : file,
        ),
      ),
    );
  };

  // for running update of the attachments
  const submitUpdatedAttachments = () => {
    setIsSubmitting(true);
    const attachments = [];

    // get attachments from project
    if (!newProjectFiles.isEmpty()) {
      var projectAttachments = newProjectFiles
        .valueSeq()
        .flatMap((group) => group.filter((item) => item.isChecked))
        .toJS();
      if (projectAttachments && projectAttachments.length > 0) {
        attachments.push(...projectAttachments);
      }
    }

    // get attachments from well
    if (!newWellboreFiles.isEmpty()) {
      var wellboreAttachments = newWellboreFiles
        .filter((file) => file.isChecked === true)
        .toJS();
      if (wellboreAttachments && wellboreAttachments.length > 0) {
        attachments.push(...wellboreAttachments);
      }
    }

    handleUpdateAttachments(attachments, uploadedFiles);
  };

  // for tabz on file selection
  const handleTabs = (event, value) => {
    setActiveTab(value);
  };

  // for toggle on the list items for projects
  const [expand, setExpand] = useState({});

  const handleToggle = (projectId) => {
    setExpand((prev) => ({ ...prev, [projectId]: !prev[projectId] }));
  };

  // for dropzone upload
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => {
      setUploadedFiles((prevFiles) => [
        ...prevFiles,
        ...acceptedFiles.map((file) => ({
          name: file.name,
          reportingDate: moment().format(ATTACHMENTS_DATE_FORMAT), // default
          content: file,
        })),
      ]);
    },
    disabled: isSubmitting,
    noClick: false,
    noKeyboard: true,
    maxSize: MAX_UPLOAD_FILE_SIZE,
  });

  const handleRemoveFile = (fileName) => {
    setUploadedFiles((prevFiles) =>
      prevFiles.filter((file) => file.name !== fileName),
    );
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            {...getRootProps()}
            className={`${classes.dropzone} ${
              isDragActive ? classes.dragActive : ''
            }`}
          >
            <input {...getInputProps()} />
            <Typography variant="body1" color="text.secondary" gutterBottom>
              Drag and drop the file here or
            </Typography>
            <Button
              variant="text"
              startIcon={<CloudUploadIcon />}
              sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
            >
              Upload File
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          >
            {uploadedFiles.map((file, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={file.name}
                  secondary={file.reportingDate}
                />
                <IconButton
                  className={classes.closeIcon}
                  aria-label="delete"
                  onClick={() => handleRemoveFile(file.name)}
                >
                  <CloseIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12}>
          <Tabs
            value={activeTab}
            onChange={handleTabs}
            className={classes.tabsContainer}
            indicatorColor="primary"
          >
            <Tab
              label="FROM WELL"
              value={INTEGRITY_ATTACHMENT_FILES_TABS.WELL}
              className={classes.tab}
            />
            <Tab
              label="FROM PROJECT"
              value={INTEGRITY_ATTACHMENT_FILES_TABS.PROJECT}
              className={classes.tab}
            />
          </Tabs>
          <Divider className={classes.divider} />
        </Grid>
        {activeTab === INTEGRITY_ATTACHMENT_FILES_TABS.WELL && (
          <Grid item xs={12}>
            <List
              sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            >
              {newWellboreFiles.map((item) => {
                const labelId = `checkbox-list-label-${item.externalId}`;

                return (
                  <ListItem
                    key={item.externalId}
                    secondaryAction={
                      <IconButton edge="end" aria-label="comments">
                        <CommentIcon />
                      </IconButton>
                    }
                    disablePadding
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={item.isChecked}
                        disabled={isSubmitting}
                        tabIndex={-1}
                        disableRipple
                        onChange={() =>
                          handleWellboreFilesCheckboxToggle(item.externalId)
                        }
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={labelId}
                      primary={item.name}
                      secondary={
                        item.modified
                          ? moment(item.modified).format(
                              ATTACHMENTS_DATE_FORMAT,
                            )
                          : 'N/A'
                      } // not needed for the table
                    />
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        )}
        {activeTab === INTEGRITY_ATTACHMENT_FILES_TABS.PROJECT && (
          <Grid item xs={12}>
            <List
              sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            >
              {newProjectFiles.entrySeq().map(([projectId, files]) => (
                <React.Fragment key={`project-${projectId}`}>
                  {/* Parent Item */}
                  <ListItem button onClick={() => handleToggle(projectId)}>
                    <ListItemText primary={`Project ID: ${projectId}`} />
                    {expand[projectId] ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>

                  {/* Collapsible Nested List of fajlz */}
                  <Collapse in={expand[projectId]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {(files.toJS ? files.toJS() : files).map((file) => {
                        const labelId = `checkbox-list-label-${projectId}-${file.externalId}`;

                        return (
                          <ListItem
                            key={`item-${projectId}-${file.externalId}`}
                            secondaryAction={
                              <IconButton edge="end" aria-label="comments">
                                <CommentIcon />
                              </IconButton>
                            }
                            sx={{ pl: 4 }} // Indentation for nested items
                          >
                            <ListItemIcon>
                              <Checkbox
                                edge="start"
                                tabIndex={-1}
                                disableRipple
                                checked={file.isChecked}
                                disabled={isSubmitting}
                                onChange={() =>
                                  handleProjectFilesCheckboxToggle(
                                    projectId,
                                    file.externalId,
                                  )
                                }
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              id={labelId}
                              primary={file.name}
                              secondary={
                                file.modified
                                  ? moment(file.modified).format(
                                      ATTACHMENTS_DATE_FORMAT,
                                    )
                                  : 'N/A'
                              } // not needed for the table
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  </Collapse>
                </React.Fragment>
              ))}
            </List>
          </Grid>
        )}
        <Grid item xs={12}>
          <Grid item container justifyContent="flex-end" spacing={1}>
            <Grid item xs={3}>
              <Button
                title="Cancel"
                color="secondary"
                variant="contained"
                disabled={isSubmitting}
                onClick={toggleDrawer}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                title="Save attachments"
                color="primary"
                variant="contained"
                disabled={isSubmitting}
                onClick={submitUpdatedAttachments}
                type="submit"
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  paperMargin: {
    marginTop: '4vh',
  },
  dropzone: {
    border: '2px dashed grey',
    borderRadius: '8px',
    padding: '20px',
    textAlign: 'center',
    backgroundColor: '#3e4246',
    cursor: 'pointer',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
  },
  dragActive: {
    borderColor: theme.palette.primary.main,
  },
  saveIcon: {
    marginLeft: 'auto',
  },
});

export default withStyles(styles)(UploadIntegrityAttachmentDrawerContent);
