// SchematicsComponentToolTip.js
import { NodeTooltip } from '@int/geotoolkit/controls/tooltip/NodeTooltip';
import { WellBoreNode } from '@int/geotoolkit/schematics/scene/WellBoreNode';
import { ToolTipFormatter } from '@int/geotoolkit/controls/tooltip/ToolTipFormatter';
import { Node } from '@int/geotoolkit/scene/Node';

export class SchematicsComponentToolTip extends NodeTooltip {
  constructor(format) {
    super(
      format ||
        '${symbol}${name} <br/> Radius: <b>${radius}</b><br/> Depth: ${depth}',
    );
  }

  getTooltip(visual, pt, registry, options) {
    const wellBorNode = Node.findParent(visual, WellBoreNode);
    if (wellBorNode == null) {
      return null;
    }
    const fillStyle = visual.getFillStyle();
    const lineStyle = visual.getLineStyle();
    const color = fillStyle != null ? fillStyle.getColor() : null;

    const schematicsPoint = wellBorNode
      .getSceneTransform()
      .inverseTransformPoint(pt);
    const radius = Math.abs(schematicsPoint.getX()); // zero is the center of schematics
    const depth = schematicsPoint.getY();

    const toolTipInfo = {
      name: visual.getDescription() || visual.getName(),
      color: color,
      symbol: ToolTipFormatter.formatSymbol(fillStyle, lineStyle || 'black'),
      radius: radius,
      depth: depth,
    };

    let formatString;
    const toolTipFormat = this.getFormat();
    if (typeof toolTipFormat === 'function') {
      formatString = toolTipFormat.call(this, visual, pt, toolTipInfo, options);
    } else {
      formatString = toolTipFormat;
    }

    return registry.getFormatter().evaluate(formatString, toolTipInfo, visual);
  }
}
