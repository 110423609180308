import React, { useEffect, useRef } from 'react';
import { Plot } from '@int/geotoolkit/plot/Plot';
import { WellLogWidget } from '@int/geotoolkit/welllog/widgets/WellLogWidget';
import { TrackType } from '@int/geotoolkit/welllog/TrackType';
import { LogCurve } from '@int/geotoolkit/welllog/LogCurve';
import { TableView } from '@int/geotoolkit/widgets/TableView';
import { DataProvider } from '@int/geotoolkit/controls/shapes/tableview/DataProvider';
import { createWellLogWidget } from './helpers/common.js';
import { useSimpleDataSourceTable } from './helpers/SimpleDataSourceTable.js';
import { Rect } from '@int/geotoolkit/util/Rect';
import { Group } from '@int/geotoolkit/scene/Group';
import { CssLayout } from '@int/geotoolkit/layout/CssLayout';
import { Point } from '@int/geotoolkit/util/Point';

const BigDataTable = () => {
  const plotRef = useRef(null);
  const dataSource = useSimpleDataSourceTable();
  const widgetRef = useRef(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    if (!dataSource) {
      console.warn('Data source is not loaded yet.');
      return;
    }

    // Create the WellLogWidget and add tracks and curves
    const widget = createWellLogWidget({ indextype: 'number', indexunit: 'm' });
    widget.addTrack(TrackType.IndexTrack).setWidth(80);

    const track = widget.addTrack(TrackType.LinearTrack);

    dataSource.curveNames.forEach((curveName, index) => {
      const color = index % 2 === 0 ? 'green' : 'orange';

      const curveData = dataSource.getCurveData(curveName);
      if (curveData) {
        const curve = new LogCurve({
          data: curveData,
        }).setLineStyle(color);
        curve.setName(curveName);
        track.addChild(curve);
      } else {
        console.warn(`No data found for curve ${curveName}`);
      }
    });

    dataSource.connect(widget);

    // Custom DataProvider using dataSource.rows and dataSource.curveNames
    class SimpleDataProvider extends DataProvider {
      constructor(rows, curveNames) {
        super();
        this.rows = rows;
        this.indexColumnName = 'depth';
        this.contentColumnNames = curveNames;
        this.rowCount = rows.length;
      }

      getContentData(col, row) {
        const columnName = this.contentColumnNames[col];
        const value = this.rows[row][columnName];
        return value != null ? value.toFixed(2) : '-';
      }

      getIndexData(row) {
        const depthValue = this.rows[row][this.indexColumnName];
        return depthValue != null ? depthValue.toFixed(2) : '-';
      }

      getHeaderData(col) {
        return this.contentColumnNames[col];
      }

      getColumnCount() {
        return this.contentColumnNames.length;
      }

      getRowCount() {
        return this.rowCount;
      }
    }

    const dataProvider = new SimpleDataProvider(
      dataSource.rows,
      dataSource.curveNames,
    );

    // Initialize the TableView
    const tableWidget = new TableView({
      horizontalscroll: 'floating',
      verticalscroll: 'floating',
      indexwidth: 60,
      bounds: new Rect(300, 0, 800, 600),
    });

    tableWidget.setData({
      dataprovider: dataProvider,
      cols: dataProvider.getColumnCount(),
      rows: dataProvider.getRowCount(),
    });

    // Synchronization between TableView and WellLogWidget

    // Crosshair synchronization
    widget.getToolByName('cross-hair').on('position-changed', (evt) => {
      const depth = evt.getPosition().getY();
      const closestRow = dataSource.depths.findIndex((val) => val >= depth);
      tableWidget.highlightRow(closestRow);
    });

    // Scroll synchronization
    widget.getToolByName('TrackPlotVerticalScroll').on('scroll', () => {
      const limits = widget.getVisibleDepthLimits();
      const topRow = Math.max(
        0,
        dataSource.depths.findIndex((val) => val >= limits.getLow()),
      );
      const bottomRow = Math.min(
        dataProvider.getRowCount() - 1,
        dataSource.depths.findIndex((val) => val >= limits.getHigh()),
      );
      tableWidget.setVisibleTableLimits(topRow, bottomRow);
    });

    // Hover synchronization
    tableWidget.getToolByName('TableViewHighlight').on('hover', (evt) => {
      const depth = dataSource.rows[evt.getRowNumber()].depth;
      widget.getToolByName('cross-hair').setPosition(new Point(0, depth));
    });

    // Create the Plot and add both widgets
    const plot = new Plot({
      canvaselement: canvasRef.current,
      root: new Group()
        .setLayout(new CssLayout())
        .addChild([widget, tableWidget]),
    });

    plotRef.current = plot;
    widgetRef.current = widget;

    return () => {
      plot.dispose();
    };
  }, [dataSource]);

  const zoomIn = () => widgetRef.current && widgetRef.current.scale(1.25);
  const zoomOut = () => widgetRef.current && widgetRef.current.scale(0.8);
  const fitToHeight = () =>
    widgetRef.current && widgetRef.current.fitToHeight();

  return (
    <div style={{ backgroundColor: 'white' }}>
      <canvas ref={canvasRef} width="1600" height="800" />
      <div>
        <button onClick={zoomIn}>Zoom In</button>
        <button onClick={zoomOut}>Zoom Out</button>
        <button onClick={fitToHeight}>Fit to Height</button>
      </div>
    </div>
  );
};

export default BigDataTable;
